import { AsyncThunk } from '@reduxjs/toolkit';
import React, { memo, useCallback, useMemo, useState } from 'react';

import { useNewsFeedLoad } from 'common/hooks/useNewsFeedLoad';
import { RCM_BLOCK_TYPE } from 'common/hooks/useRcm';
import { FetchRecs } from 'common/redux/pages/home/asyncs';
import { FetchRegionRecs } from 'common/redux/pages/region/asyncs';
import { FetchTopicRecs } from 'common/redux/pages/topic/asyncs';
import { selectIsHumanCenteredStrategyStateActivated } from 'common/redux/runtime/selectors';
import { PageContextForTrigger } from 'desktop/pages/context';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { CHUNK_FIFTH, CHUNK_FOURTH } from '../BaseRedesign/constants';

import s from './styles.module.css';

const RECS_TO_LOAD = 12;
export const START_LOADER_COUNTER_VALUE = 0;

const getLength = (counter: number) => {
  if (counter === START_LOADER_COUNTER_VALUE) {
    return CHUNK_FOURTH;
  }

  return CHUNK_FIFTH;
};

export type FetchRecsType = FetchRecs | FetchTopicRecs | FetchRegionRecs;

type TriggerPropsType = {
  ComponentRecommends: React.ElementType;
  fetchRecsData: AsyncThunk<void, FetchRecsType, {}>;
  excludedRecsIds: string[];
  rcmBlockType?: RCM_BLOCK_TYPE;
  hasNextPage: boolean;
};

/**
 * Компонент для подгрузки кластеров.
 * @param ComponentRecommends - компонент для подгрузки рекомендов;
 * @param fetchRecsData - функция получения данных рекоммендов;
 * @param excludedRecsIds - айдишники исключаемых кластеров для рекомендов;
 * @param rcmBlockType - алиас, под которым хранится BlockID в сторе;
 * @param hasNextPage - флаг для возможности подгрузки кластеров.
 */
export const Trigger = memo(
  ({
    ComponentRecommends,
    fetchRecsData,
    excludedRecsIds,
    rcmBlockType,
    hasNextPage,
  }: TriggerPropsType) => {
    const dispatch = useAppDispatch();

    const forceRedesign = useAppSelector(
      selectIsHumanCenteredStrategyStateActivated,
    );

    const [triggerReady, setTriggerReady] = useState(false);
    const [loaderCounter, setLoaderCounter] = useState(
      START_LOADER_COUNTER_VALUE,
    );

    const contextValue = useMemo(
      () => ({ triggerReady, setTriggerReady }),
      [triggerReady],
    );

    const loadRecommendations = useCallback(
      () =>
        dispatch(
          fetchRecsData({
            length: forceRedesign ? getLength(loaderCounter) : RECS_TO_LOAD,
            excludedClustersIds: excludedRecsIds,
            rcmBlockType,
          }),
        ),
      [
        dispatch,
        forceRedesign,
        fetchRecsData,
        excludedRecsIds,
        rcmBlockType,
        loaderCounter,
      ],
    );

    const { triggerComponent } = useNewsFeedLoad({
      callback: loadRecommendations,
      forceLoader: hasNextPage,
      setLoaderCounter,
    });

    return (
      <PageContextForTrigger.Provider value={contextValue}>
        {/* Компонент для подгрузки рекомендов (если ее реализовывать не обособленно, то проблемы с бесконечным циклом в useEffect) */}
        <ComponentRecommends />
        {triggerReady && hasNextPage && (
          <div className={s.trigger}>{triggerComponent}</div>
        )}
      </PageContextForTrigger.Provider>
    );
  },
);

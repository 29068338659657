import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { CardConfigValue } from 'common/components/CardLayout/context';
import { RCM_BLOCK_TYPE } from 'common/hooks/useRcm';
import { selectPageName } from 'common/redux/appController/selectors';
import { getIsListPage } from 'config/constants/common';
import { isVideoWidgetType } from 'desktop/components/LevelLayout/utils';

type UseCardConfigValuePropsType = {
  clustersIds: CardData['id'][];
  rcmBlockType?: RCM_BLOCK_TYPE;
};

/**
 * Хук, генерирующий топ100 разметку для карточек.
 * @param props.clustersIds - массив id кластеров;
 * @param props.rcmBlockType - тип блока, для которого генерируется разметка рекомендаций.
 */
export const useCardConfigValue = ({
  clustersIds,
  rcmBlockType,
}: UseCardConfigValuePropsType) => {
  const pageName = useSelector(selectPageName);

  const isListPage = getIsListPage(pageName);

  const getTop100 = useCallback<CardConfigValue['getTop100']>(
    ({ type, level, index }) => {
      // индекс начинается с 1 поэтому убрала clusterIds.length - 1
      const isRec =
        typeof index === 'number' && index > clustersIds.length && !isListPage;

      if (isVideoWidgetType(type) && level) {
        return `video_widget::${level}`;
      }

      if (isVideoWidgetType(type)) {
        return '';
      }

      if (isRec) {
        return `rcm_news_block::${(index as number) - clustersIds.length}`;
      }

      if (isListPage) {
        return `news_list::${typeof index === 'number' ? index + 1 : ''}`;
      }

      return `top_news_block::${index}`;
    },
    [clustersIds.length, isListPage],
  );

  const getRecBlockType = useCallback<CardConfigValue['getRecBlockType']>(
    ({ index }) => {
      if (index > clustersIds.length - 1 && !isListPage && rcmBlockType) {
        return rcmBlockType;
      }

      return null;
    },
    [clustersIds.length, rcmBlockType, isListPage],
  );

  const value = useMemo<CardConfigValue>(
    () => ({
      getTop100,
      getRecBlockType,
    }),
    [getTop100, getRecBlockType],
  );

  return { value };
};

import { StateTopicType } from 'common/redux/commonData/topics/typings';
import { PROJECT_IDS } from 'config/constants/projects/constants';

const REGEXP_FILTER_FOR_FINANCE = /currencies|calculators/;
const PATTERN_FILTER_FOR_TRAVEL = 'guide';

/**
 * Функция получения списка топиков для виджетов на странице вертикали/топика
 * @param props.topics – массив всех топиков;
 * @param props.excludedTopicAlias – алиас исключаемого топика;
 * @param props.projectId – id вертикали.
 */
export const getTopicAliasesForWidgets = ({
  topics,
  projectId,
  excludedTopicAlias,
}: {
  topics: StateTopicType[];
  projectId: PROJECT_IDS;
  excludedTopicAlias?: TopicType['alias'];
}) => {
  const topicsAliases = topics
    .filter((topic) => topic.alias !== excludedTopicAlias)
    .map((topic) => topic.alias);

  if (projectId === PROJECT_IDS.Finance) {
    return topicsAliases.filter(
      (topic) => topic.search(REGEXP_FILTER_FOR_FINANCE) === -1,
    );
  }

  if (projectId === PROJECT_IDS.Travel) {
    return topicsAliases.filter(
      (topic) => !topic.includes(PATTERN_FILTER_FOR_TRAVEL),
    );
  }

  return topicsAliases;
};

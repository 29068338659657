import cn from 'classnames';
import _isequal from 'lodash.isequal';
import React, { memo, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Ad } from 'common/components/Ad';
import { ErrorBoundary } from 'common/components/ErrorBoundary';
import { ReachGoalFirstCardWrapper } from 'common/components/metrics/ReachGoalFirstCardWrapper';
import { ReachGoalLevelWrapper } from 'common/components/metrics/ReachGoalLevelWrapper';
import { SberWidgetWrapper } from 'common/components/SberWidget';
import { CommonTitle, DataTitleType } from 'common/pages/titles/CommonTitle';
import {
  selectWidgetsTopProjectAllClusterIds,
  selectWidgetsTopProjectFirstClusterID,
} from 'common/redux/commonData/widgets/topProjectWidget/selectors';
import { TAG_WIDGET } from 'common/redux/commonData/widgets/topProjectWidget/typings';
import {
  selectIsRussia,
  selectPromoBannerEnabled,
  selectPromoBannerPlaceholderEnabled,
} from 'common/redux/runtime/selectors';
import Banners from 'config/constants/banner/banners';
import { PROMO_BANNER_DESKTOP_PLACEHOLDER_HEIGHT } from 'config/constants/banner/contants';
import {
  modifyPuids,
  NATIVE_LIST_VERSION_PUIDS,
  PuidsType,
} from 'config/constants/common';
import { CardSwitcher } from 'desktop/components/Card';
import { MainCard } from 'desktop/components/Card/components/MainCard';
import { CARD_TYPES } from 'desktop/components/Card/constants';
import { TopMainNewsWidget } from 'desktop/components/TopNewsWidget';

import { WidgetsColumn } from './WidgetsColumn';

import s from './styles.module.css';

import 'desktop/css/banner--native-context_widget.css';
import 'desktop/css/banner--100_70.css';
import 'desktop/css/banner--native-context_list_new_redesign.css';

const START_CARD_POSITION = 2;

const FIRST_CARD_INDEX = 1;

const TOP_CARDS_COUNT_COMPACT = 8;
const TOP_CARDS_COUNT = 5;

const BOTTOM_CARDS_COUNT_COMPACT = 8;
const BOTTOM_CARDS_COUNT = 5;

const NATIVE_PLACEMENT = 3;

type FirstPageWithWidgetsByTopPropsType = {
  clusterIds: string[];
  puids: PuidsType;
  isCompact?: boolean;
  excludedIds?: CardData['id'][];
  dataTitle?: DataTitleType;
};

/**
 * Компонент шаблона первого блока новостей с виджетами мнений, новость часа, и топом разных вертикалей.
 * @param clusterIds - функция-селектор кластеров для страницы;
 * @param puids - настройки для рекламных блоков;
 * @param isCompact - флаг, что экран узкий;
 * @param excludedIds - исключенные из выдачи кластера для виджета.
 */
export const FirstPageWithWidgetsByTop = memo(
  ({
    clusterIds,
    puids,
    isCompact = false,
    excludedIds = [],
    dataTitle,
  }: FirstPageWithWidgetsByTopPropsType) => {
    const topWidgetsIds = useSelector(
      selectWidgetsTopProjectAllClusterIds,
      shallowEqual,
    );
    const isRussiaRegion = useSelector(selectIsRussia);
    const isPromoWidgetEnabled = useSelector(selectPromoBannerEnabled);
    const isPromoWidgetPlaceholderEnabled = useSelector(
      selectPromoBannerPlaceholderEnabled,
    );
    const hotWidgetId = useSelector(
      selectWidgetsTopProjectFirstClusterID(TAG_WIDGET.HotNews),
      shallowEqual,
    );
    const opinionsWidgetId = useSelector(
      selectWidgetsTopProjectFirstClusterID(TAG_WIDGET.Mnenia),
      shallowEqual,
    );

    const {
      firstClusterId,
      firstClusterBlock,
      secondClusterBlock,
      nativeIndex,
    } = useMemo(() => {
      const slicePlaceFirst = isCompact
        ? TOP_CARDS_COUNT_COMPACT
        : TOP_CARDS_COUNT;
      const slicePlaceSecond = isCompact
        ? slicePlaceFirst + BOTTOM_CARDS_COUNT_COMPACT
        : slicePlaceFirst + BOTTOM_CARDS_COUNT;

      return {
        firstClusterId: clusterIds[0],
        firstClusterBlock: clusterIds?.slice(FIRST_CARD_INDEX, slicePlaceFirst),
        secondClusterBlock: clusterIds?.slice(
          slicePlaceFirst,
          slicePlaceSecond,
        ),
        nativeIndex: NATIVE_PLACEMENT,
      };
    }, [clusterIds, isCompact]);

    const nativePuids = useMemo(
      () => modifyPuids(puids, NATIVE_LIST_VERSION_PUIDS),
      [puids],
    );

    const excludeIds = useMemo(
      () => [...excludedIds, ...topWidgetsIds, hotWidgetId, opinionsWidgetId],
      [excludedIds, hotWidgetId, opinionsWidgetId, topWidgetsIds],
    );

    return (
      <div className={s.root}>
        {dataTitle && (
          <div className={s.title}>
            <CommonTitle dataTitle={dataTitle} />
          </div>
        )}

        <ReachGoalLevelWrapper level={1} styles={s.clustersList}>
          <ReachGoalFirstCardWrapper>
            <MainCard clusterId={firstClusterId} index={1} />
          </ReachGoalFirstCardWrapper>

          {firstClusterBlock.map((clusterId, index) => {
            const cardPosition = index + START_CARD_POSITION;

            return (
              <CardSwitcher
                key={clusterId}
                clusterId={clusterId}
                type={CARD_TYPES.LIST}
                index={cardPosition}
                isCompactCard
              />
            );
          })}
        </ReachGoalLevelWrapper>

        <div className={s.widgetsColumn}>
          <WidgetsColumn puids={nativePuids} />
        </div>

        <div
          className={cn(
            s.contextBanner,
            dataTitle && s.contextBanner_withTitle,
          )}
        >
          <Ad
            name={Banners['100x70']}
            className="banner--100_70"
            puids={puids}
            withoutMarginBottom
          />
        </div>

        <div className={cn(s.clustersList, s.clustersListBottom)}>
          {secondClusterBlock.map((clusterId, index) => {
            const cardPosition =
              index + firstClusterBlock.length + START_CARD_POSITION;
            const adKey = `ad_${clusterId}`;

            const item = (
              <CardSwitcher
                key={clusterId}
                clusterId={clusterId}
                type={CARD_TYPES.LIST}
                index={cardPosition}
              />
            );

            if (nativeIndex === index) {
              return [
                <Ad
                  key={adKey}
                  name={Banners.Native}
                  puids={puids}
                  className={cn(
                    s.adPlaceholder,
                    s.native,
                    'banner--native-context--redesign_list_new',
                  )}
                  loadedClassName={s.adPlaceholderLoaded}
                />,
                item,
              ];
            }

            return item;
          })}
        </div>

        {!isCompact && (
          <div className={s.latestWidget}>
            {/* Скрыт виджет "Последние новости" - NEWS-11319 */}
            {/* <LatestNewsWidget excludedIds={excludedIds} /> */}
            <ErrorBoundary componentName="TopMainNewsWidget">
              <TopMainNewsWidget excludeIds={excludeIds} level={1} />
            </ErrorBoundary>
          </div>
        )}

        <div
          className={cn(s.rightColumn, dataTitle && s.rightColumn_withTitle)}
        >
          <div className={s.sticky}>
            {isRussiaRegion && isPromoWidgetEnabled && (
              <Ad
                name={Banners.PromoWidget}
                puids={puids}
                isLazy={false}
                /* Рисуем отступ снизу если плейсхолдер виджета включен или он отрендерился */
                className={cn(
                  s.promo,
                  isPromoWidgetPlaceholderEnabled && s.visible,
                )}
                preloadHeight={PROMO_BANNER_DESKTOP_PLACEHOLDER_HEIGHT}
                disablePlaceholder={!isPromoWidgetPlaceholderEnabled}
                loadedClassName={s.visible}
              />
            )}
            <SberWidgetWrapper />
            <Ad
              className={s.ad240_400}
              puids={puids}
              name={Banners['240x400']}
              isSticky={!isCompact}
              withoutMarginBottom
              isLazy={false}
            />
            {/* Скрыт виджет "Последние новости" - NEWS-11319 */}
            {/* {isCompact && <LatestNewsWidget excludedIds={excludedIds} />} */}
            {isCompact && (
              <ErrorBoundary componentName="TopMainNewsWidget">
                <TopMainNewsWidget excludeIds={excludeIds} level={1} />
              </ErrorBoundary>
            )}
          </div>
        </div>
      </div>
    );
  },
  (prev, next) =>
    _isequal(prev.clusterIds, next.clusterIds) &&
    _isequal(prev.excludedIds, next.excludedIds) &&
    prev.isCompact === next.isCompact,
);

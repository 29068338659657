import React from 'react';

import { Ad } from 'common/components/Ad';
import Banners from 'config/constants/banner/banners';
import { PuidsType } from 'config/constants/common';

import s from './styles.module.css';

const CHUNK_FIRST = 7;
const CHUNK_SECOND = 5;
const CHUNK_THIRD = 5;
export const CHUNK_FOURTH = 8;
export const CHUNK_FIFTH = 9;
export const CHUNK_LAST = CHUNK_FIFTH;

export const SLICE_CONFIG = [
  CHUNK_FIRST,
  CHUNK_SECOND,
  CHUNK_THIRD,
  CHUNK_FOURTH,
  CHUNK_FIFTH,
];

const NATIVE_BANNER_LIST_CLASS_NAME =
  'banner--native-context--redesign_list_relaunch';
const NATIVE_BANNER_PRELOAD_HEIGHT = 115;

export const INJECTORS = [
  (index: number, puids: PuidsType) => {
    if (index === 3) {
      return (
        <Ad
          key={`injected-ad_${index}`}
          name={Banners.Native}
          puids={puids}
          className={NATIVE_BANNER_LIST_CLASS_NAME}
          loadedClassName={s.adPlaceholderLoaded}
          preloadHeight={100}
        />
      );
    }
  },
  (index: number, puids: PuidsType) => {
    if (index === 3) {
      return (
        <Ad
          key={`injected-ad_${index}`}
          name={Banners.Native}
          puids={puids}
          className={NATIVE_BANNER_LIST_CLASS_NAME}
          loadedClassName={s.adPlaceholderLoaded}
          preloadHeight={NATIVE_BANNER_PRELOAD_HEIGHT}
        />
      );
    }
  },
  (index: number, puids: PuidsType) => {
    if (index === 1) {
      return (
        <Ad
          key={`injected-ad_${index}`}
          name={Banners.Native}
          puids={puids}
          className={NATIVE_BANNER_LIST_CLASS_NAME}
          preloadHeight={NATIVE_BANNER_PRELOAD_HEIGHT}
          loadedClassName={s.adPlaceholderLoaded}
        />
      );
    }

    if (index === 2) {
      return (
        <Ad
          key={`injected-ad_${index}`}
          name={Banners.NativeInRecs}
          puids={puids}
          withoutMarginBottom
          className={NATIVE_BANNER_LIST_CLASS_NAME}
          preloadHeight={164}
          loadedClassName={s.adPlaceholderLoaded}
        />
      );
    }
  },
  (index: number, puids: PuidsType) => {
    if (index === 1) {
      return (
        <Ad
          key={`injected-ad_${index}`}
          name={Banners.Native}
          puids={puids}
          className={NATIVE_BANNER_LIST_CLASS_NAME}
          preloadHeight={NATIVE_BANNER_PRELOAD_HEIGHT}
          loadedClassName={s.adPlaceholderLoaded}
        />
      );
    }
  },
];

import React, { memo, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { CardLayout } from 'common/components/CardLayout';
import { useCardConfig } from 'common/components/CardLayout/context';
import { selectCardById } from 'common/redux/commonData/entries/selectors';
import { datesince } from 'utils/date/dtime';

import s from './styles.module.css';

type MainCardPropsType = {
  clusterId: CardData['id'] | null;
  index: number;
  onClick?: () => void;
};

const IMAGE_WIDTH = 690; // Изначальный размер на макетах 460 пикселей. Выходит слишком мыльно, поэтому сделали в 1.5 раза больше
const IMAGE_HEIGHT = 488; // Изначальный размер на макетах 325. То же самое

/**
 * DESKTOP
 *
 * Карточка главной новости reDesign-версии
 *
 * Является функциональной обёрткой над CardLayout, достаёт кластер по id, формирует пропсы, и передаёт стили.
 * @param clusterId - id кластера, используется для поиска его в entries и последующей отрисовки
 * @param index - индекс кластера в глобальном отношении;
 * @param onClick – функция клик по карточке
 */

export const MainCard = memo(
  ({ clusterId, index, onClick }: MainCardPropsType) => {
    const cardData = useSelector(selectCardById(clusterId), shallowEqual);

    const { getTop100 } = useCardConfig();

    const imageParams = useMemo(
      () => ({
        s3: cardData?.image?.s3,
        width: IMAGE_WIDTH,
        height: IMAGE_HEIGHT,
      }),
      [cardData?.image?.s3],
    );

    if (!cardData) return null;

    const {
      title,
      image: { url: imageUrl },
      url,
      commentsCount,
      publicationTime,
      video: { recordId, isLive },
      topic,
      noComments,
    } = cardData;

    const formattedDate = publicationTime
      ? datesince({
          pubdate: new Date(publicationTime).getTime() / 1000,
          isShort: true,
        })
      : undefined;

    const top100Value = getTop100({ card: cardData, index });

    return (
      <CardLayout
        date={formattedDate}
        commentsCount={commentsCount}
        clusterUrl={url}
        title={title}
        imageSrc={imageUrl}
        imageParams={imageParams}
        recordId={recordId}
        isLive={isLive}
        style={s}
        top100={top100Value}
        topicName={topic}
        noComments={noComments}
        onClick={onClick}
      />
    );
  },
);

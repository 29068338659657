import { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { fetchTopProjectNews } from 'common/redux/commonData/widgets/topProjectWidget/asyncs';
import {
  selectWidgetsTopProject,
  selectWidgetsTopProjectClusterInCardData,
  selectWidgetsTopProjectErrorState,
  selectWidgetsTopProjectLoadingState,
} from 'common/redux/commonData/widgets/topProjectWidget/selectors';
import { selectClusterPageClusterFeed } from 'common/redux/pages/cluster/selectors';
import { TOP_ID_PROJECT } from 'config/constants/tops';
import { useAppDispatch } from 'store/hooks';

const SAVING_TOP_LIMIT = 4;

type UseTopProjectNewsWidgetType = (
  projectId: ProjectType['id'],
  index: number,
  withoutSlice?: boolean,
) => {
  /**
   * Кластер для отображения
   */
  cluster: CardData;
  /**
   * Состояние виджета (загружается или нет)
   */
  isLoading: boolean;
  /**
   * Состояние виджета (есть ошибка или нет)
   */
  isError: boolean;
  /**
   * Функция загрузки данных
   */
  fetchData: () => void;
};

/**
 * Хук для виджета последних новостей.
 * @param projectId - id проекта, для которого загружаются новости;
 * @param index - индекс проекта, для которого извлекается нужная новость
 * @param withoutSlice - булево значение по обрезки массива кластеров
 */
export const useTopProjectNewsWidget: UseTopProjectNewsWidgetType = (
  projectId,
  index,
  withoutSlice = false,
) => {
  const dispatch = useAppDispatch();

  const clusters = useSelector(
    selectWidgetsTopProjectClusterInCardData(projectId),
    shallowEqual,
  );
  const widgetData = useSelector(
    selectWidgetsTopProject(projectId),
    shallowEqual,
  );
  const feedList = useSelector(selectClusterPageClusterFeed, shallowEqual);
  const isLoading = useSelector(selectWidgetsTopProjectLoadingState(projectId));
  const error = useSelector(selectWidgetsTopProjectErrorState(projectId));

  // исключаем уже загруженные кластеры в бесконечке и виджете "главное сейчас"
  const excludedIds = [...feedList];

  const fetchData = useCallback(() => {
    if (!widgetData && !isLoading) {
      dispatch(fetchTopProjectNews({ projectId, topId: TOP_ID_PROJECT }));
    }
  }, [dispatch, projectId, widgetData, isLoading]);

  const cluster = clusters
    .filter((item) => !excludedIds.includes(item?.id as string))
    .slice(0, withoutSlice ? clusters.length : SAVING_TOP_LIMIT)[
    index
  ] as CardData;

  return {
    cluster,
    isLoading,
    isError: !!error,
    fetchData,
  };
};

import React, { CSSProperties, useState } from 'react';
import { useSelector } from 'react-redux';

import { RamblerLoader } from 'common/components/RamblerComponents/RamblerLoader';
import { useObserver } from 'common/hooks/useObserver';
import { selectIsMobile } from 'common/redux/runtime/selectors';
import { START_LOADER_COUNTER_VALUE } from 'desktop/components/Trigger';
import { Skeletons } from 'mobile/components/Skeletons';

const observerStyles: CSSProperties = {
  position: 'relative',
  top: '-70vh',
};

type UseNewsFeedLoadType = ({
  callback,
  forceLoader,
  loaderCallback,
  setLoaderCounter,
}: {
  callback: (() => unknown) | null;
  forceLoader?: boolean;
  loaderCallback?: () => React.JSX.Element;
  setLoaderCounter?: React.Dispatch<React.SetStateAction<number>>;
}) => {
  isLoading: boolean;
  triggerComponent: JSX.Element;
};

/**
 * Получение компонента для предзагрузки.
 * @param isMobile - флаг мобилы;
 */
const renderLoader = (isMobile: boolean) =>
  isMobile ? <Skeletons /> : <RamblerLoader size="medium" />;

/**
 * Хук для бесконечной подгрузки кластеров по триггеру.
 * @param props.callback - функция подгрузки кластеров;
 * @param props.forceLoader - принудительный показ лоадера;
 * @param props.loaderCallback - функция рендера для лоадера;
 * @param props.setLoaderCounter - сеттер счетчика тригера подгрузки рекомендов.
 */
export const useNewsFeedLoad: UseNewsFeedLoadType = ({
  callback,
  forceLoader = false,
  loaderCallback,
  setLoaderCounter,
}) => {
  const isMobile = useSelector(selectIsMobile);

  const [isLoading, setIsLoading] = useState(false);

  const loader = loaderCallback ? loaderCallback() : renderLoader(isMobile);

  const callbackHandler = callback
    ? async () => {
        setIsLoading(true);

        await callback();

        setLoaderCounter?.((prev) => {
          if (prev === START_LOADER_COUNTER_VALUE) {
            return prev + 1;
          }

          return prev;
        });

        setIsLoading(false);
      }
    : null;

  const intersectionRef = useObserver<HTMLDivElement>({
    callback: callbackHandler,
    entryCheck: true,
    observerConfig: {
      threshold: 0,
    },
  });

  const LoaderComponent = isLoading || forceLoader ? loader : null;

  const ObserverComponent = !isLoading ? (
    <div style={observerStyles} ref={intersectionRef} />
  ) : null;

  const TriggerComponent = (
    <>
      {LoaderComponent}
      {ObserverComponent}
    </>
  );

  return {
    isLoading,
    triggerComponent: TriggerComponent,
  };
};

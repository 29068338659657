import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useTopicsMenu } from 'common/components/TopicsMenu/useTopicsMenu';
import { selectProjectId } from 'common/redux/runtime/selectors';
import { getTopicAliasesForWidgets } from 'utils/getTopicAliasesForWidgets';

/**
 * Хук, выдающий функцию, выдающую топик по переданному индексу.
 * @param excludedTopicAlias - алиас исключаемого топика.
 * @returns Функция, которая возвращает один из топиков в списке по индексу.
 *  Если индекс больше, чем длина массива с топиками, то отсчет начинается с нуля.
 */
export const useTopicAliasesForWidgets = (
  excludedTopicAlias: TopicType['alias'] = '',
) => {
  const projectId = useSelector(selectProjectId);
  const topics = useTopicsMenu(projectId);

  const topicsForWidget = useMemo(
    () => getTopicAliasesForWidgets({ topics, excludedTopicAlias, projectId }),
    [projectId, topics, excludedTopicAlias],
  );

  return useCallback(
    (index: number) => topicsForWidget[index % topicsForWidget.length],
    [topicsForWidget],
  );
};

import _isequal from 'lodash.isequal';
import React, { memo } from 'react';

import { useWidgetReachGoal } from 'common/components/metrics/utils';
import { FeedWidgetLayout } from 'desktop/components/FeedWidgetLayout';

import { CardItem } from './components/CardItem';
import { useTopicNews } from './hooks/useTopicNews';

type TopicNewsWidgetPropsType = {
  topicAlias: string;
  excludedIds?: CardData['id'][];
  newsCount?: number;
  level?: number;
};

/**
 * Виджет новостей по топику
 * @param props.topicAlias - алиас топика
 * @param props.excludedIds - исключенные из выдачи кластера
 * @param props.newsCount - кастомное кол-во новостей в виджете
 * @param props.level - номер этажа на котором находится виджет, используется для отправки ЯМ
 */
export const TopicNewsWidget = memo(
  ({
    topicAlias,
    excludedIds = [],
    newsCount,
    level,
  }: TopicNewsWidgetPropsType) => {
    const { title, clusters, isError, fetchData } = useTopicNews(
      topicAlias,
      excludedIds,
    );

    const {
      reachGoalNewsClickCallback,
      reachGoalMoreNewsClickCallback,
      widgetRef,
    } = useWidgetReachGoal(level);

    const clustersByCount = newsCount ? clusters.slice(0, newsCount) : clusters;

    return (
      <div ref={widgetRef}>
        <FeedWidgetLayout
          title={title}
          url={`/${topicAlias}/`}
          top100Value={topicAlias}
          isError={isError}
          onRefresh={fetchData}
          onMoreNewsClick={reachGoalMoreNewsClickCallback}
        >
          {clustersByCount.map(
            (
              {
                id,
                publicationTime,
                url,
                image,
                title: clusterTitle,
                commentsCount,
              },
              index,
            ) => (
              <CardItem
                key={id}
                index={index}
                publicationTime={publicationTime}
                url={url}
                imageUrl={image.url}
                imageS3={image.s3}
                title={clusterTitle}
                commentsCount={commentsCount}
                onClick={reachGoalNewsClickCallback}
              />
            ),
          )}
        </FeedWidgetLayout>
      </div>
    );
  },
  (prev, next) =>
    prev.topicAlias === next.topicAlias &&
    _isequal(prev.excludedIds, next.excludedIds) &&
    prev.newsCount === next.newsCount,
);

import { AsyncThunk } from '@reduxjs/toolkit';
import { useCallback, useEffect } from 'react';

import { RCM_BLOCK_TYPE } from 'common/hooks/useRcm';
import { FetchRecs } from 'common/redux/pages/home/asyncs';
import { FetchRegionRecs } from 'common/redux/pages/region/asyncs';
import { FetchTopicRecs } from 'common/redux/pages/topic/asyncs';
import { selectIsHumanCenteredStrategyStateActivated } from 'common/redux/runtime/selectors';
import { usePageContextForTrigger } from 'desktop/pages/context';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const RECS_TO_FIRST_LOAD = 12;
const RECS_REDESIGN_TO_FIRST_LOAD = 5;

type UseFetchRecsDataPropsType = {
  fetchRecsData: AsyncThunk<
    void,
    FetchRecs | FetchTopicRecs | FetchRegionRecs,
    {}
  >;
  excludedClustersArray: CardData['id'][][];
  rcmBlockType?: RCM_BLOCK_TYPE;
};

/**
 * Хук для запроса в рекоменды.
 * @param props.fetchRecsData - функция получения данных рекоммендов;
 * @param props.excludedClustersArray - массив массивов айдишников кластеров для исключения из выдачи;
 * @param props.rcmBlockType - алиас, под которым хранится BlockID в сторе.
 */
export const useFetchRecsData = ({
  fetchRecsData,
  excludedClustersArray,
  rcmBlockType,
}: UseFetchRecsDataPropsType) => {
  const dispatch = useAppDispatch();

  const forceRedesign = useAppSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );

  const { setTriggerReady } = usePageContextForTrigger();

  const checkForFetch = useCallback(() => {
    const booleanArray = excludedClustersArray.map(
      (excludedClusterArray) => excludedClusterArray.length !== 0,
    );

    return !booleanArray.some((value) => value === false);
  }, [excludedClustersArray]);

  const dispatchFetchRecsData = useCallback(async () => {
    const excludedClustersIds = excludedClustersArray.flat();

    await dispatch(
      fetchRecsData({
        length: forceRedesign
          ? RECS_REDESIGN_TO_FIRST_LOAD
          : RECS_TO_FIRST_LOAD,
        excludedClustersIds,
        rcmBlockType,
      }),
    );

    setTriggerReady(true);
  }, [
    dispatch,
    forceRedesign,
    fetchRecsData,
    excludedClustersArray,
    rcmBlockType,
    setTriggerReady,
  ]);

  useEffect(() => {
    const isFetched = checkForFetch();

    if (!isFetched) return;

    dispatchFetchRecsData();
  }, [checkForFetch, dispatchFetchRecsData]);
};
